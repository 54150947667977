#benefits-section {
  overflow: hidden;
  scroll-padding-top: 100px;

  .header-row {
    margin: 150px 0;

    @include mdAndBelow() {
      margin: 80px 0;
    }
  }

  .curled-bg-wrapper {
    background-image: url(../../../assets/curl-path.svg);
    background-repeat: no-repeat;
    background-position: 50% 30%;

    @media (min-width: 1600px) {
      background-size: 150%;
    }

    &:last-child {
      background-position: 50% 50%;
    }
  }
}
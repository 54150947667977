#employers-section {
  padding: 150px 0;
  position: relative;
  overflow: hidden;

  @include mdAndBelow() {
    padding: 80px 0;
  }

  .background-image {
    background: url('../../../assets/hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(5px);
  }

  .content-row {
    @include mdAndBelow() {
      flex-direction: column-reverse;
    }
  }

  .testimonial__card {
    padding: 0;
    background-color: $white;
    border-radius: 6px;

    .testimonial-image {
      width: 100%;
      border-radius: 6px 6px 0 0;
      height: 250px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    p {
      color: $darks-pallette-3;
    }

    &-body {
      padding: 30px;
    }

    &-testimonial-text {
      font-size: rem(18px);
    }

    &-person-name {
      margin-bottom: 0;
      text-transform: capitalize;
    }

    &-footer-text {
      font-size: rem(14px);
    }
  }

  .react-multi-carousel-dot {

    &-list {
      bottom: -40px;
    }

    button {
      border: none;
      width: 10px;
      height: 10px;
    }

    &--active button {
      background: $primary-color;
    }
  }

  .testimonials-wrapper {
    @include mdAndBelow() {
      margin:  0 auto;
      margin-top: 50px;
    }
  }

  .content-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;

    h2 {
      @include mdAndBelow() {
        font-size: rem(40px);
      }
    }

    .logos-wrapper {
      position: relative;

      @include mdAndBelow() {
        margin-bottom: 60px;
      }
    }

    .react-multi-carousel-item {
      .logo__card {
        margin: 0 5%;
        border-radius: 12px;
        padding: 5px 0;
        background-color: rgba($color: $white, $alpha: 0.5);
        height: 100%;
        display: grid;
        place-items: center;
    
        .logo-image {
          width: 80%;
        }
      }
    }
  }
}
.home-page {
  .header-row {
    .subtitle {
      margin-bottom: 8px;

      @include mdAndBelow() {
        margin-bottom: 4px;
      }
    }

    h2 {
      margin-bottom: 10px;

      @include smAndBelow() {
        word-break: break-word;
      }
    }

    .lead-text {
      margin-bottom: 0;
    }

    .lead-text + .cta-wrapper {
      margin-top: 60px;

      @include mdOnly() {
        margin-top: 40px;
      }

      @include smAndBelow() {
        margin-top: 30px;
      }
    }
  }

  .cta-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;

    @include smAndBelow() {
      gap: 20px;
    }

    a {
      white-space: nowrap;

      @include smAndBelow() {
        white-space: unset;
      }
    }
  }

  @include smAndBelow() {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
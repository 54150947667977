.cta-wrapper {
  a {
    color: $white;
    text-transform: uppercase;
    font-family: $fontGilroyBold;
    font-size: rem(14px);

    &:hover {
      color: $primary-color;
    }
  }
}

@import 'hero';
@import './visuals';
@import './benefits';
@import './services';
@import './markets';
@import './about';
.visuals {
  .wrapper {
    display: flex;
    .left {
      display: flex;
      align-items: center;

      img {
        height: 70%;
        margin-left: -9.9rem;

        @include smAndBelow() {
          height: 50%;
          margin-left: -7rem;
        }

        @include xsOnly() {
          margin-left: -10rem;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include smAndBelow() {
        justify-content: space-around;
      }

      img:nth-child(1) {
        height: 55%;
        margin-left: 1rem;

        @include mdAndBelow() {
          transform: scaleX(-1);
        }

        @include smAndBelow() {
          height: 40%;
          margin-left: -9rem;
        }

        @include xsOnly() {
          margin-left: -11rem;
        }
      }

      img:nth-child(2) {
        height: 30%;
        margin-left: 1rem;

        @include smAndBelow() {
          height: 25%;
          margin-left: -8rem;
        }

        @include xsOnly() {
          margin-top: -5rem;
          margin-left: -9rem;
        }
      }
    }
  }

  &.right {
    padding-right: 0;
    .wrapper {
      .left {
        img {
          margin-left: -15rem;
          height: 50%;

          @include smAndBelow() {
            margin-left: -20rem;
            height: 35%;
          }

          @include xsOnly() {
            margin-left: -18rem;
          }
        }
      }

      .right {
        img:nth-child(1) {
          margin-left: 10rem;
          height: 30%;

          @include smAndBelow() {
            margin-left: -10rem;
            height: 25%;
          }

          @include xsOnly() {
            margin-left: -14rem;
          }
        }

        img:nth-child(2) {
          margin-left: 3rem;
          height: 60%;

          @include mdAndBelow() {
            transform: scaleX(-1);
          }

          @include smAndBelow() {
            margin-left: -9rem;
            height: 50%;
          }

          @include xsOnly() {
            margin-left: -16rem;
            height: 40%;
            margin-top: -7rem;
          }
        }
      }
    }
  }
}

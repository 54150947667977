.dropdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  > span {
    font-weight: bold;
    font-size: rem(14px);
  }

  .select-dropdown {
    
    &-container {
      width: 100px;
      margin-left: 10px;
    }

    &__control {
      background-color: transparent;
      border: 1px solid $darks-pallette-3;
      box-shadow: none;
      cursor: pointer;
    }

    &__placeholder, &__input {
      color: $darks-pallette-3;
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__indicator {
      padding-top: 5px;
      padding-bottom: 5px;

      > svg {
        fill: $darks-pallette-3;
      }
    }

    &__menu {
      z-index: 1000;
      background-color: $white;
    }

    &__option {
      font-size: rem(14px);
      cursor: pointer;
      
      &--is-focused {
        background-color: rgba($color: $primary-color, $alpha: 0.1);
      }

      &--is-selected {
        background-color: $primary-color;
      }
    }

    &__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__single-value {
      color: $darks-pallette-3;
      font-size: rem(14px);
    }
  }
}
.vacancy-view-wrapper {
  background-color: $lights-pallette-5;
  padding-top: 50px;
  padding-bottom: 80px;

  @include mdAndBelow() {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h1, h2, h3, h4, h5, h6, p, span {
    color: $darks-pallette-3;
  }

  h1 {
    text-align: center;
    margin-bottom: 45px;

    span {
      color: $lights-pallette-7;
    }

    @include smAndBelow() {
      margin-bottom: 30px;
    }
  }

  .back-row {
    .button-link {
      border: none;
      outline: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: rem(14px);
      line-height: rem(17px);
      font-family: $fontGilroyBold;

      svg {
        width: 24px;
        height: 24px;
        border: 1px solid rgba($color: $black, $alpha: 0.1);
        border-radius: 3px;
        margin-right: 20px;
        background-color: $white;
      }
    }

    .post-time {
      display: flex;
      justify-content: flex-end;
      font-size: rem(14px);
      line-height: rem(17px);
      font-family: $fontGilroyBold;
    }
  }

  .content-wrapper {
    padding: 80px;
    background-color: $white;
    margin-top: 30px;
    margin-bottom: 80px;
    position: relative;

    @include mdAndBelow() {
      padding: 40px;
      margin-bottom: 60px;
    }

    @include smAndBelow() {
      padding: 40px 20px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  .btn-share {
    display: grid;
    place-items: center;
    background-color: transparent;
    border: 1px solid $lights-pallette-2;
    position: absolute;
    right: 80px;
    top: 20px;
    border-radius: 3px;
    border-color: $lights-pallette-7;

    @include xsOnly() {
      right: unset;
      left: 20px; 
    }

    span {
      color: $lights-pallette-7;
    }

    &:hover {
      border-color: $primary-color;

      span {
        color: $primary-color;
      }
    }
  }

  .recruiter-card {
    img {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 4px;
    }
  
    .contact-links {
      li {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 5px;

        i {
          color: $primary-color;
        }

        a {
          white-space: nowrap;
          font-size: rem(14px);
          line-height: rem(18px);
        }
      }
    }
  }

  .content {

    img {
      width: 100%;
    }

    p {
      margin-bottom: 20px;
      font-size: rem(18px);
      line-height: rem(36px);

      &:first-child {
        margin-bottom: 50px;
      }
    }

    h4 {
      margin-bottom: 10px;
      margin-top: 30px;
      font-size: rem(28px);
      line-height: rem(34px);
    }
  }

  .cta {
    margin-top: 60px;

    .btn {
      margin: 0 20px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
.content-page {
  header {
    height: 440px;
    background: url("../../assets/hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: grid;
    place-items: center;

    h1 {
      text-align: center;
      margin-top: 100px;
      word-break: break-word;
    }

    @include mdAndBelow() {
      height: max-content;
      padding: 80px 0;
    }
  }

  .content {
    padding: 100px 0 120px;
    margin:  0 auto;

    @include mdAndBelow() {
      padding: 80px 0 60px;
    }

    .col-lg-10 {
      margin: 0 auto;
    }

    h3 {
      margin-top: 60px;
      margin-bottom: 20px;

      @include mdAndBelow() {
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }

    p {
      margin-bottom: 30px;

      @include mdAndBelow() {
        margin-bottom: 20px;
      }
    }
  }
}
.filters-wrapper {
  align-items: center;
  padding: 20px 0;
  background-color: $darks-pallette-4;

  .col-lg-2, .col-lg-4 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .select-dropdown {
    &-container, &__control {
      width: 100%; 
    }

    &__control {
      border: none;
      box-shadow: none;
      cursor: pointer;
    }

    &__value-container {
      padding: 0;
      margin: 0;
    }

    &__indicator-separator {
      width: 0;
    }

    &__indicator {
      display: none;
    }

    &__single-value {
      font-family: $fontGilroyBold;
      color: $black;
    }

    &__placeholder {
      font-family: $fontGilroyBold;
      color: $black;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__menu {
      display: initial;
    }

    &__menu:empty {
      // width: max-content;
      display: none;
    }

    &__option {
      font-size: rem(16px);
      cursor: pointer;

      &--is-focused {
        background-color: rgba($color: $primary-color, $alpha: 0.1);
      }

      &--is-selected {
        background-color: $primary-color;
      }

      ~ .select-dropdown__menu {
        display: initial;
      }
    }
  }

  
  .container > .row {  
    > * + * {
      @media (max-width: 991px) {
        margin-top: 20px;
      }

      @include smAndBelow() {
        margin-top: 15px;
      }
    }
  }

  .keyword-filter {
    display: flex;
    align-items: center;
    padding: 11px 14px 10px 14px;
    border: 1px solid $lights-pallette-6;
    border-radius: 5px;
    background-color: $white;
    height: 100%;

    svg {
      width: 18px;
      height: 18px;
      fill: $black;
    }

    input {
      border: none;
      outline: none;
      font-family: $fontGilroyBold;
      padding-left: 10px;
      width: 100%;

      &::placeholder {
        color: $black;
      }
    }
  }

  .location-filter {
    display: flex;
    align-items: center;
    border: 1px solid $lights-pallette-6;
    padding: 11px 14px 10px 14px;
    border-radius: 5px;
    background-color: $white;
    height: 57px;

    .distance-filter {
      display: flex;
      align-items: center;
      border: 1px solid $lights-pallette-3;
      border-radius: 4px;
      padding: 0 8px;

      > span {
        margin-right: 2px;
        color: $lights-pallette-7;
        font-family: $fontRobotoCondensed;
      }

      input {
        width: 100%;
        border: none;
        outline: none;
        font-family: $fontRobotoCondensed;
      }
    }
  }

  .employment-filter, .industry-filter {
    border: 1px solid $lights-pallette-6;
    padding: 9px 14px 8px 14px;
    border-radius: 5px;
    background-color: $white;
  }

  .salary-range-filter {
    display: flex;
    align-items: center;
    border: 1px solid $lights-pallette-6;
    padding: 11px 14px 10px 14px;
    border-radius: 5px;
    background-color: $white;

    @media (max-width: 991px) {
      padding: 3px 14px 2px 14px;
    }

    input {
      border: none;
      outline: none;
      color: $black;
      font-family: $fontRobotoCondensed;
    }

    > span {
      font-family: $fontGilroyBold;
    }

    .salary-range-min {
      margin-left: auto;
    }

    .salary-range-input-container {
      border: 1px solid $lights-pallette-3;
      border-radius: 4px;
      margin: 0 10px;
      padding-left: 5px;
      display: flex;
      align-items: center;
      width: 100%;

      > span {
        color: $lights-pallette-7;
        font-family: $fontRobotoCondensed;
      }

      input {
        width: 100%;

        @media (max-width: 991px) {
          padding: 8px 0 7px 5px;
        }
      }
    }

    .salary-range-max {
      @media (max-width: 991px) {
        margin-right: 0;
      }
    }
  }

  .btn {
    display: grid;
    place-items: center;
    padding: 0 40px;
    height: 57px;

    @media (max-width: 991px) {
      margin: 0 auto;
    }

    @include smAndBelow() {
      width: 100%;
    }
  }
}
input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.form-check {
  display: flex;
  align-items: center;
  margin: 20px auto;

  input {
    width: 1.3rem;
    height: 1.3rem;
    margin-top: -0.2rem;

    &:checked {
      background-color: $primary-color;
    }
  }

  label {
    font-size: rem(16px);
    line-height: rem(24px);
    margin-left: 1rem;

    a {
      color: $primary-color;
      text-decoration: none;
    }
  }
}
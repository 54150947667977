.file-uploader-wrapper {
  width: 70%;

  @include smAndBelow() {
    width: 100%;
  }

  .file-uploader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $lights-pallette-1;
    height: 51px;
    border-radius: 3px;
    padding-left: 10px;
    
    span {
      color: rgba($color: $black, $alpha: 0.6);
    }

    button {
      border: none;
      outline: none;
      background-color: $lights-pallette-1;
      font-size: rem(16px);
      height: 100%;
      padding: 0 10px;
      border-radius: 0 3px 3px 0;

      &:hover {
        background-color: $lights-pallette-2;
        color: $white;
      }
    }
  }
}
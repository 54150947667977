.homepage-nav {
  background-color: transparent;
  padding-top: 40px;
  position: absolute;
  width: 100%;

  .row {
    @include smAndBelow() {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .nav-link {
    text-transform: uppercase;
    color: $white;

    &:hover {
      color: $primary-color;
    }
  }

  // Mobile styles
  .master-app-bar-container {

    @include mdAndBelow() {
      .content {
        display: none;
      }

      .item.icon {
        display: block;
        float: right;
        align-items: center;
        color: $white;
        height: max-content;
        align-self: center;
        font-size: 22px;
      }
    }

    .sidenav-container {
      .horizontal-line {
        border: none;
      }
      
      .content {
        overflow: auto;
        display: flex;
  
        a {
          color: $white;
          padding: 10px 15px;
          text-transform: uppercase;
          font-size: rem(16px);
          font-family: $fontGilroyBold;
  
          &:hover, &.active {
            text-decoration: none;
            color: $darks-pallette-3;
          }
        }

        .btn-primary {
          padding: 10px 15px;
          font-size: rem(16px);
          margin: 0;

          &:hover {
            color: $darks-pallette-3;
          }
        }
      }
    }
  }
}

.btn-primary.btn__nav {
  background-color: transparent !important;
  padding: 0;
  color: $white;
  border: none;
  text-transform: uppercase;
  font-size: rem(14px);
  font-family: $primary-font;
  letter-spacing: 0;
  margin: 0 10px;
  margin-bottom: 1px;
  outline: 0 none;
  font-family: $fontGilroyBold;
  letter-spacing: 0.88px;

  &:hover {
    color: $primary-color;
    background-color: transparent !important;
  }

  &:active {
    border-color: none;
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
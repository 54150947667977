.btn {

  &__nav {
    padding: 10px 20px 8px;
    background-color: rgba($color: $white, $alpha: 0.2);
    border: 2px solid $white;
    color: $white !important;
    margin-left: 20px;

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
    }
  }
}
.testimonials-section {
  padding: 150px 0;
  background-color: $darks-pallette-1;

  @include mdAndBelow() {
    padding: 80px 0;
  }

  .container {
    position: relative;
  }

  .testimonial__card {

    .image-container {
      position: absolute;
      left: 30px;
      top: 5%;
      height: 90%;

      &::after {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $white, $alpha: 0.33);
        z-index: 1;
      }

      @include mdAndBelow() {
        display: none;
      }
    }

    .backdrop-image {
      height: 100%;
      transform: scaleX(-1);

      @include mdAndBelow() {
        display: none;
      }
    }

    .subtitle {
      margin-bottom: 8px;

      @include mdAndBelow() {
        margin-bottom: 4px;
      }
    }

    h3 {
      margin-bottom: 8px;
    }

    .large-text {
      margin-bottom: 25px;

      @include mdAndBelow() {
        margin-bottom: 20px;
      }
    }

    .medium-text {
      margin-bottom: 0;
      text-transform: capitalize;
      font-size: rem(18px);
      color: rgba($color: $white, $alpha: 0.33);

      @include smAndBelow() {
        font-size: rem(16px);
      }
    }

    .testimonial-image {
      position: relative;
      z-index: 2;
      width: 100%;

      @media (max-width: 400px) {
        width: 100%;
      }
    }

    .col-lg-4 {
      @include mdAndBelow() {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
      }
    }
  }

  .arrow-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include xsOnly() {
      right: 30px;
    }

    img {
      height: 20px;

      @include xsOnly() {
        height: 15px;
      }
    }

    img:nth-child(2) {
      height: 30px;
      margin: 0 20px;

      @include xsOnly() {
        height: 25px;
        margin: 0 15px;
      }
    }
  }
}
.vacancy-list-wrapper {
  background-color: $lights-pallette-5;
  padding: 80px 0;

  @include smAndBelow() {
    padding: 60px 0;
  }

  h1, h2, h3, h4, h5, h6, p, span {
    color: $darks-pallette-3;
  }

  h1 {
    text-align: center;
  }

  .sort-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @include smAndBelow() {
      gap: unset;
      flex-flow: column;

      > * + * {
        margin-top: 30px;
      }
    }
  }

  .btn-clear {
    background-color: transparent;
    padding: 10px 14px 9px;
    font-size: rem(14px);
    line-height: rem(18px);
    text-transform: uppercase;
    color: $primary-color;
    font-family: $fontTertiary;
    border: 1px solid $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
    }

    &:disabled {
      color: $lights-pallette-7;
      border: 1px solid $lights-pallette-7;

      &:hover {
        background-color: $lights-pallette-7;
        color: $darks-pallette-4;
      }
    }
  }

  .vacancy-list {
    list-style-type: none;
    margin-top: 80px;
    margin-bottom: 40px;
    padding: 0;

    @include smAndBelow() {
      margin-top: 40px;
      margin-bottom: 0;
    }

    li {
      margin-bottom: 48px;

      @include smAndBelow() {
        margin-bottom: 24px;
      }

      .vacancy-wrapper {
        border: 1px solid $lights-pallette-3;
        border-radius: 4px;
        background-color: $white;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        cursor: pointer;
      }

      header {
        border-radius: 4px 4px 0 0;
      }

      .header-content {
        padding: 20px 30px 0;

        @include smAndBelow() {
          padding: 20px 20px 0;
        }
      }

      .footer {
        padding: 0 30px 20px;

        @include smAndBelow() {
          padding: 0 20px 20px;
        }
      }

      .vacancy-image-container {
        height: 200px;
        border-radius: 4px 4px 0 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.4);
          border-radius: 4px 4px 0 0;
        }
      }

      .subtitle {
        font-family: $fontSecondary;
        font-size: rem(16px);
        line-height: rem(19px);
        color: $lights-pallette-4;
        margin-bottom: 0;
        text-transform: capitalize;
      }

      h4 {
        margin: 3px 0 10px 0;
        font-size: rem(24px);
        line-height: rem(29px);
      }

      .description {
        font-size: rem(16px);
        line-height: rem(28px);
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p {
          font-size: rem(16px);
          line-height: rem(28px);
          margin-bottom: 0;
          font-weight: bold;
        }

        a {
          border: 1px solid $lights-pallette-3;
          display: grid;
          place-items: center;
          padding: 5px;
          border-radius: 3px;

          svg {
            width: 25px;
            height: 25px;
          }

          &:hover {
            background-color: $primary-color;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .vacancy-list-footer {
    align-items: center;

    @include smAndBelow() {
      flex-direction: column;
    }

    .per-page-container {
      display: flex;
      justify-content: flex-start;

    @include smAndBelow() {
      justify-content: center;
      order: 3;
    }
      
      .label {
        font-size: rem(14px);
        font-weight: 600;
      }

      .select-dropdown-container {
        width: 60px;

        .select-dropdown {
          &__control {
            border: none;
          }

          &__value-container {
            padding-right: 0;
          }

          &__single-value {
            font-size: rem(14px);
          }

          &__indicator {
            padding-left: 0;
          }
        }
      }
    }

    .pagination-wrapper {
      display: flex;
      justify-content: center;

      @include smAndBelow() {
        order: 2;
        margin-bottom: 20px;
      }

      .btn-page {
        background-color: transparent;
        padding: 0;
        margin: 0 8px;
        border: none;
        outline: none;
        font-size: rem(14px);
        font-family: $fontTertiary;

        &.active, &:hover {
          color: $primary-color;
        }
      }

      .btn-prev {
        margin-right: 15px;
      }

      .btn-next {
        margin-left: 15px;
      }

      .btn-prev, .btn-next {
        border: 1px solid $lights-pallette-3;
        display: grid;
        place-items: center;
        padding: 2px;
        border-radius: 3px;

        svg {
          width: 25px;
          height: 25px;
        }

        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: $primary-color;

          svg {
            fill: $white;
          }
        }
      }
    }

    .info-wrapper {
      display: flex;
      justify-content: flex-end;

      @include smAndBelow() {
        justify-content: center;
        order: 1;
        margin-bottom: 50px;
      }

      span {
        font-size: rem(14px);
      }
    }
  }
}
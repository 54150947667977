.homepage-hero {
  height: 800px;
  background: url("../../../assets/hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;
  position: relative;

  @include mdAndBelow() {
    height: max-content;
    padding: 80px 0;
  }

  .scroll-down-image {
    position: absolute;
    left: 50px;
    bottom: -50px;
    width: 70px;

    @include mdAndBelow() {
      display: none;
    }
  }

  h1 {
    margin-bottom: 10px;

    @include mdAndBelow() {
      margin-top: 80px;
    }

    @include smAndBelow() {
      word-break: break-word;
    }
  }

  .lead-text {
    margin-bottom: 60px;

    @include mdOnly() {
      margin-bottom: 40px;
    }

    @include smAndBelow() {
      margin-bottom: 30px;
    }
  }

  .btn {
    @include xsOnly() {
      white-space: nowrap;

      @include xsOnly() {
        padding: rem(13px) rem(20px);
      }
    }
  }
}
footer {
  background-color: $darks-pallette-3;
  padding: 80px 0 35px;

  @include smAndBelow() {
    padding: 40px 0 30px;
  }

  .row {

    @include smAndBelow() {
      margin-left: 0;
      margin-right: 0;
    }

    @media (max-width: 400px) {
      .col {
        display: flex;
        flex-flow: column;

        a {
          margin-left: 0;
        }

        > * + * {
          margin-top: 20px;
        }
      }
    }

    h4 {
      margin-bottom: 5px;
    }

    p {
      font-family: $fontTertiary;
    }

    .medium-text {
      @include smAndBelow() {
        font-size: rem(14px);
      }
    }

    &--links {
      margin-top: 60px;

      @include smAndBelow() {
        margin-top: 20px;
      }

      a {
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .contact-links {
      margin-top: 20px;
      font-size: smaller;

      a {
        margin-left: 10px;
        font-weight: bold;
      }

      ul {
        
        li {
          margin-top: 5px;

          i {
            color: $white;
          }
        }
      }
    }

    .quick-links {
      display: flex;
      flex-flow: column;
      list-style-type: none;
      padding: 0;

      a {
        color: $white;
        font-family: $fontTertiary;
        font-size: rem(16px);
        line-height: rem(32px);

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
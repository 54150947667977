.contact {
  header {
    margin-top: 100px;
    margin-bottom: 40px;

    @include forMobile() {
      margin-bottom: 0;
    }

    h1 {
      margin-bottom: 20px;

      @include forMobile() {
        margin-bottom: 10px;
      }
    }
  }

  .cf-firstname {
    @include forMobile() {
      margin-top: 2.5rem;
    }
  }

  label {
    color: $white;
  }

  .form-container {
    margin-bottom: 200px;

    @include smAndBelow() {
      flex-flow: column-reverse;
      margin-bottom: 0;

      .map-wrapper {
        margin-top: 60px;
      }
    }
  }
}
html {
  scroll-behavior: smooth;
}

body {
  background-color: $darks-pallette-2;
}

h1, h2, h3, h4, h5, h6, p {
  color: $white;
}

.subtitle, .subtitle p {
  color: rgba($color: $white, $alpha: 0.33);
}
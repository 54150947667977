#markets-section {
  padding: 150px 0;
  background-image: url(../../../assets/curl-path.svg);
  background-repeat: no-repeat;
  background-position: -55rem 1rem;

  @include mdAndBelow() {
    padding: 80px 0;
  }

  .markets-row {
    margin: 80px 0 50px 0;

    @include mdOnly() {
      margin: 50px 0;
    }

    @include smAndBelow() {
      margin: 40px 0;
    }
  }

  .col-md-6 {
    position: relative;

    img {
      width: 100%;
    }

    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 80%;
      text-align: center;
    }

    @include smAndBelow() {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cta-wrapper {
    justify-content: center;
  }
}
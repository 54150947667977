$primary-color: #FF5C5C;

$white: #ffffff;
$black: #000000;

$lights-pallette-1: #D8D8D8;
$lights-pallette-2: #9b9b9b;
$lights-pallette-3: #E8ECF2;
$lights-pallette-4: #BBC6D7;
$lights-pallette-5: #F9FBFE;
$lights-pallette-6: #D5DCE6;
$lights-pallette-7: #90A3AE;

$darks-pallette-1: #2b2d3b;
$darks-pallette-2: #1e1f2a;
$darks-pallette-3: #14161d;
$darks-pallette-4: #36394B;

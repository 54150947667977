.secondary-nav {
  padding-bottom: 15px;
  padding-top: 40px;
  background-color: $darks-pallette-1;
  position: relative;

  h4 {
    margin-bottom: 0;
  }

  .col-md-6 {
    display: flex;
    align-items: center;
  }

  .btn__nav {
    @include smAndBelow() {
      margin-right: 20px;
    }
  }
}
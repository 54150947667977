#about-section {
  padding: 150px 0;
  background-image: url(../../../assets/curl-path.svg);
  background-repeat: no-repeat;
  background-position: -15rem 10rem;

  @include mdAndBelow() {
    padding: 80px 0;
  }

  @media (min-width: 1600px) {
    background-size: 150%;
    background-position: center center;
  }

  .row {
    align-items: center;

    @include forIpad {
      flex-direction: column-reverse;
    }
  }

  .about-img {
    // width: 100%;

    @include mdAndBelow() {
      width: inherit;
      margin-right: 0;
    }
  }

  .header-column {
    @include smAndBelow() {
      margin-bottom: 40px;
      margin-left: 0;
    }
  }

  #team-section {
    padding-top: 150px;

    @include mdAndBelow() {
      padding-top: 80px;
    }

    h2 {
      margin-bottom: 0;
    }

    .team-row {
      margin-top: 60px;
      align-items: flex-start;
      flex-direction: row;

      @include smAndBelow() {
        margin-top: 40px;
      }

      img {
        width: 100%;
        margin-bottom: 40px;

        @include smAndBelow() {
          margin-bottom: 20px;
        }
      }

      h3 {
        margin-bottom: 3px;
      }

      h4 {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;

        @include smAndBelow() {
          margin-bottom: 40px;
        }
      }

      .contact-links {
        margin-top: 20px;
        font-size: smaller;

        a {
          margin-left: 10px;
          font-weight: bold;
        }

        ul {
          li {
            margin-top: 5px;
          }

          i {
            color: $white;
          }
        }
      }

      .col-md-4 {
        &:last-child {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@import 'fonts';
@import 'colors';

// Font family overrides
$primary-font: $fontPrimary;
$secondary-font: $fontSecondary;
$tertiary-font: $fontTertiary;

// Font size overrides
$body-font-size: rem(18px);
$h1--font-size: rem(72px);
$h1--mobile-font-size: 3rem;
$h2--font-size: rem(60px);
$h2--mobile-font-size: 2.5rem;
$h3--font-size: rem(36px);
$h4--font-size: rem(24px);
$h4--font-size: rem(20px);
$h6--font-size: rem(16px);
$p--font-size: rem(24px);
$btn--font-size: rem(18px);

// Button style overrides
$btn--border-radius: 4px;
$btn--padding-vertical: 13px;
$btn--padding-horizontal: 80px;
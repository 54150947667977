// Overriding React Responsive Modal package styling

.react-responsive-modal-container {
  height: 100vh;
  overflow: hidden;

  @include smAndBelow() {
    padding-right: 40px;
  }
}

.react-responsive-modal-modal {
  height: 97vh;
  overflow: auto;

  h3, h6 {
    color: $darks-pallette-3;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 40px;

    @include smAndBelow() {
      margin-bottom: 20px;
    }
  }

  width: 100%;
  max-width: 680px !important;

  form {
    display: flex;
    justify-content: center;
    flex-flow: column;
    
    .input-container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .input-container {
        margin-bottom: 0;
        width: 70%;

        @include smAndBelow() {
          width: 100%;
        }

        input {
          width: 100%;
        }
      }

      @include smAndBelow() {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
      }

      label {
        width: 30%;
        align-self: start;
        margin-top: 10px;
        margin-bottom: 0;

        @include smAndBelow() {
          width: 100%;
          margin-bottom: 5px;
        }
      }

      input, textarea {
        width: 70%;

        @include smAndBelow() {
          width: 100%;
        }
      }

      input {
        &[type="text"], &[type="email"] {
          padding: 3px 10px 2px 10px;
        }
      }
    
      textarea {
        padding: 3px 10px 2px 10px;
        border: 1px solid $lights-pallette-1;
        border-radius: 3px;
        resize: none;

        &:focus {
          border: 1px solid $primary-color;
        }
      }
    }

    .btn-primary {
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 30px;

      @include smAndBelow() {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

#social-share-modal {
  height: auto;
}
#services-section {
  background-color: $darks-pallette-1;
  padding: 150px 0;

  @include mdAndBelow() {
    padding: 80px 0;
  }

  .services-row {
    margin: 80px 0 50px 0;

    @include mdOnly() {
      margin: 50px 0;
    }

    @include smAndBelow() {
      margin: 40px 0;
    }
  }

  .col-md-6 {
    position: relative;

    @include mdOnly() {
      &:last-child {
        margin: 0 auto;
        margin-top: 30px;
      }
    }

    @include smAndBelow() {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: 100%;
    }

    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      margin-bottom: 0;
      width: 80%;
      text-align: center;
    }
  }

  .cta-wrapper {
    justify-content: center;
  }
}
@import "fonts";

.subtitle, .subtitle p {
  font-family: $fontRobotoCondensed;
  font-size: rem(16px);
  text-transform: uppercase;

  @include smAndBelow() {
    font-size: rem(14px);
  }
}

.lead-text {
  font-size: rem(32px);
  line-height: rem(60px);

  @include mdOnly() {
    font-size: rem(24px);
    line-height: rem(44px);
  }

  @include smAndBelow() {
    font-size: rem(20px);
    line-height: rem(36px);
  }
}

.small-text {
  font-size: rem(12px);
  line-height: rem(18px);
  font-family: $fontRobotoCondensed;
}

.medium-text {
  font-size: rem(16px);
  line-height: rem(32px);
  font-family: $fontPrimary;
}

.large-text {
  font-size: rem(24px);
  line-height: rem(36px);

  @include mdAndBelow() {
    font-size: rem(18px);
    line-height: rem(32px);
  }
}

.btn {
  letter-spacing: 1px;
  font-family: $fontGilroyBold;

  &__nav {
    font-size: rem(14px);
    text-transform: uppercase;
    letter-spacing: 0.88px;
  }
}

a {
  &:hover {
    text-decoration: none;
  }

  &.nav-link {
    font-size: rem(14px);
    text-transform: uppercase;
    font-family: $fontGilroyBold;
    letter-spacing: 0.88px;
  }

  &.link {
    &--inline {
      text-decoration: underline;
    }

    &--small {
      font-size: rem(12px);
    }
  }
}
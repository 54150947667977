a {
  &.link {
    &--inline {
      color: $white;
  
      &:hover {
        color: $primary-color;
      }
    }

    &--small {
      color: $white;
      opacity: 0.3;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.select-language {
  position: absolute;
  right: 30px;
  top: 0;
  z-index: 3;

  @include xsOnly() {
    right: 15px;
  }
  
  img {
    width: 25px;
    height: 25px;
  }

  .dropdown-menu {
    left: -60px;

    a:hover {
      background-color: rgba($color: $primary-color, $alpha: 0.1);
    }
  }
  
  .dropdown-toggle {
    color: $white;
    text-transform: uppercase;
    font-size: rem(14px);
  
    img {
      margin-left: 3px;
    }
  
    &:hover {
      color: $primary-color;
    }
  }
}
.Toastify__toast-container {
  .Toastify__toast {
    padding-left: 20px;
    border-radius: 3px;

    &--success {
      background-color: $green;
    }

    &--error {
      background-color: $red;
    }
  }

  .Toastify__close-button {
    margin-top: 8px;
    padding-right: 5px;
  }
}
